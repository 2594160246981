<template>
    <div class="nav">
        <div class="nav-wrapper">
            <div class="nav-inner">
                <a class="nav-logo">
                    <img :src="webp(require(`../../../public/img/news/logo-koikeya.png`))" alt="Koikeya">
                </a>
                <div class="nav-btn">
                    <div class="nav-btn__inner" :class="{active: menuOpen}" @click="menuOpen = !menuOpen">
                        <span></span><span></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="nav-content" :class="{open: menuOpen}">
            <div class="nav-content__wrapper">
                <div class="nav-content__inner">
                    <div class="nav-content__list">
                        <div class="nav-content__item fadeup"><a href="/" class="nav-content__link">Home</a></div>
                        <div class="nav-content__item fadeup"><a href="/corporate/" class="nav-content__link" :class="{active: page==='coporate'}">{{ $t(`navigation.corporate`) }}</a></div>
                        <div class="nav-content__item fadeup"><a href="/product/karamucho" class="nav-content__link" :class="{active: page==='karamucho'}">KARAMUCHO</a></div>
                        <div class="nav-content__item fadeup"><a href="/product/koimucho" class="nav-content__link" :class="{active: page==='koimucho'}">KoiMUCHO</a></div>
                        <div class="nav-content__logo fadeup">
                            <a href="/product/karamucho" class="nav-content__logo__karamocho">
                                <img :src="webp(require(`../../../public/img/menu-logo-karamucho.png`))" alt="">
                            </a>
                            <a href="/product/koimucho" class="nav-content__logo__koimocho">
                                <img :src="webp(require(`../../../public/img/menu-logo-koimucho.png`))" alt="">
                            </a>
                        </div>
                        <div class="nav-content__social fadeup">
                            <a href="https://www.facebook.com/Karamucho.Vn/" class="nav-content__social__item" target="_blank">
                                <img :src="webp(require(`../../../public/img/icon/icon-facebook.png`))" alt="">
                            </a>
                            <a href="https://www.youtube.com/channel/UCUNnWTLL2CyNMmF2dpbd9xg" class="nav-content__social__item" target="_blank">
                                <img :src="webp(require(`../../../public/img/icon/icon-youtube.png`))" alt="">
                            </a>
                            <a href="javascript:void(0)" class="nav-content__social__item language" @click="[changeLocale(switchedLocale), menuOpen = !menuOpen]">{{ switchedLocale.toUpperCase() }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="nav-right">
            <div class="nav-right__content">
                <div class="nav-right__logo">
                    <a href="/product/karamucho" class="nav-right__logo__karamocho">
                        <img :src="webp(require(`../../../public/img/menu-logo-karamucho.png`))" alt="karamucho">
                    </a>
                    <a href="/product/koimucho" class="nav-right__logo__koimocho">
                        <img :src="webp(require(`../../../public/img/menu-logo-koimucho.png`))" alt="koimucho">
                    </a>
                </div>
                <ul class="nav-right__social">
                    <li class="nav-right__social__item">
                        <a href="https://www.facebook.com/Karamucho.Vn/">
                            <img :src="webp(require(`../../../public/img/icon/icon-facebook-black.png`))" alt="facebook">
                        </a>
                    </li>
                    <li class="nav-right__social__item">
                        <a href="https://www.youtube.com/channel/UCUNnWTLL2CyNMmF2dpbd9xg">
                            <img :src="webp(require(`../../../public/img/icon/icon-youtube-black.png`))" alt="youtube">
                        </a>
                    </li>
                    <li class="nav-right__social__item language"><a href="javascript:void(0)" @click="changeLocale(switchedLocale)">{{ switchedLocale.toUpperCase() }}</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Navigation',
        props: {
            num: Number,
            page: String,
            locale: String
        },
        data() {
            return {
                menuOpen: false,
                screenWidth: 0,
                dataEl: null
            }
        },
        computed: {
            switchedLocale() {
                return this.locale === 'vi' ? 'en' : 'vi'
            }
        },
        watch: {
            menuOpen: function () {
                let html = document.querySelector('html');
                if (this.menuOpen) {
                    html.classList.add('menu-open')
                } else {
                    html.classList.remove('menu-open')
                }
            }
        },
        methods: {
            changeLocale(locale) {
                this.$emit('changeLocale', locale)
            }
        },
        mounted() {
            this.screenWidth = window.outerWidth;
        }

    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/mixin/_mixin';

    $sm: 768;
    $md: 992;
    $lg: 1200;

    .nav {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        z-index: 9;
        transition: all .2s ease-in-out;

        .menu-open & {
            z-index: 9999;
        }

        &-wrapper {
            padding: 15px 10px 20px;
            background: #000 url("../../../public/img/news/bg/bg-nav-x.jpg") repeat-x left bottom / auto 15px;
            @include media(set-min-w, $md) {
                background-size: auto;
                padding-bottom: 30px;
            }
        }

        &-inner {
            position: relative;
            display: flex;
            align-items: center;
            height: 50px;
            @include media(set-min-w, $sm) {
                justify-content: center;
            }
            @include media(set-min-w, $md) {
                height: auto;
            }
        }

        &-logo {
            display: block;
            height: 5rem;
            @include media(set-min-w, $md) {
                height: 6.5rem;
            }
            @include media(set-min-w, $lg) {
                height: auto;
            }

            img {
                max-height: 100%;
            }
        }

        &-content {
            overflow: hidden;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 2px;
            height: 0;
            background: #000000;
            z-index: -1;
            transition: width .3s cubic-bezier(0.165, 0.84, 0.44, 1), height .3s .3s cubic-bezier(0.165, 0.84, 0.44, 1);

            &.open {
                transition: width .4s .3s cubic-bezier(0.165, 0.84, 0.44, 1), height .3s cubic-bezier(0.165, 0.84, 0.44, 1);
                width: 101%;
                height: 101%;
                z-index: 999;

                .fadeup {
                    opacity: 1;
                    transform: translateY(0);
                    transition: .4s ease;

                    @for $i from 1 through 10 {
                        &:nth-child(#{$i}) {
                            transition-delay: (0.1s * $i) + .6s
                        }
                    }
                }
            }

            &__wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                //width: 600px;
                max-width: 100%;
                padding: 0 2rem;
                margin: 0 auto;
            }

            &__inner {
                text-align: center;
            }

            &__item {
                padding: .5rem 0;
                opacity: 0;
                transform: translateY(30px);

            }

            &__link {
                position: relative;
                font-size: 5.6rem;
                font-weight: 400;
                color: #6d6d6d;
                letter-spacing: 2px;
                text-transform: capitalize;

                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 2px;
                    width: 0;
                    transition: .4s ease-in-out;
                    background: #ffffff;
                }

                &:hover {
                    &:after {
                        width: 100%;

                        @include media(phone) {
                            display: none
                        }
                    }
                }

                @include media(phone) {
                    font-size: 3rem;
                }

                &.active {
                    color: #fff;
                    font-weight: 700;
                }
            }

            &__logo {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 2rem -3.5rem 0;
                opacity: 0;
                transform: translateY(30px);

                &__karamocho,
                &__koimocho {
                    width: 50%;
                    padding: 0 3.5rem;

                    @include media(phone) {
                        width: 40%;
                    }
                }
            }

            &__social {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 3rem;
                opacity: 0;
                transform: translateY(30px);

                &__item {
                    padding: 0 4.5rem;

                    @include media(phone) {
                        padding: 0 3rem
                    }
                }

                .language {
                    color: #cacaca;
                    font-size: 2rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    text-decoration: underline;
                }
            }
        }

        &-btn {
            position: absolute;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;

            .menu-open & {
                z-index: 9999;
            }

            &.fixed {
                position: fixed;
                top: 20px;
                right: 6.5rem;
            }

            &__inner {
                position: relative;
                width: 4.4rem;
                height: 4.4rem;
                cursor: pointer;

                &:hover {
                    span {
                        &:last-child {
                            width: 100%;
                        }
                    }
                }

                &.active {
                    span {
                        transition: .4s .4s cubic-bezier(.165, .84, .44, 1), width .4s cubic-bezier(.165, .84, .44, 1);

                        &:first-child {
                            top: 22px;
                            transform: rotate(45deg)
                        }

                        &:last-child {
                            top: 22px;
                            transform: rotate(135deg);
                            width: 100%;
                        }
                    }
                }

                span {
                    position: absolute;
                    right: 0;
                    height: 3px;
                    width: 100%;
                    background: #fff;
                    transition: .4s cubic-bezier(.165, .84, .44, 1), width .4s .4s cubic-bezier(.165, .84, .44, 1);

                    &:first-child {
                        top: 18px
                    }

                    &:last-child {
                        top: 26px;
                        width: 60%;
                    }
                }
            }
        }

        .nav-right {
            position: fixed;
            top: 14.7rem;
            right: 0;
            bottom: 0;
            z-index: 9;
            display: none;
            width: 18rem;
            border-left: 1px solid #e6e6e6;
            @include media(set-min-w, $lg) {
                display: block;
            }
            @include media(set-min-w, 1600) {
                width: 27rem;
            }

            &__content {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding: 5rem 2rem 0;
            }

            &__logo {
                display: flex;
                flex-direction: column;
                max-width: 100px;
                margin-bottom: 2rem;

                &__karamocho {
                    margin-bottom: 3rem;
                }
            }

            &__social {
                margin-right: .5rem;
                text-align: center;

                &__item {
                    margin: 3rem 0;
                }

                .language {
                    font-size: 1.8rem;

                    a {
                        color: #333033;
                        font-weight: 800;
                    }
                }
            }
        }
    }
    .webp {
        .nav {
            &-wrapper {
                background: #000 url("../../../public/img/news/bg/bg-nav-x.webp") repeat-x left bottom / auto 15px;
            }
        }
    }
</style>